<template>
  <wide-panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        dense
        card
      >
        <v-menu
          v-model="menu"
          close-on-content-click
          transition="scale-transition"
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="monthOf"
            prepend-icon="event"
            readonly
          />
          <v-date-picker
            v-model="monthOf"
            min="2024-04"
            :max="maxDate"
            :allowed-dates="allowedMonths"
            type="month"
            year-icon="date_range"
          />
        </v-menu>
        <v-select
          v-model="filter"
          :items="['OneXOX','XOX']"
          label="Filter by"
          clearable
        />
        <v-spacer />
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              fab
              dark
              small
              :disabled="!enableExport"
              :loading="!enableExport"
              color="green"
              v-on="on"
              @click="exportReport"
            >
              <v-icon dark>
                mdi-microsoft-excel
              </v-icon>
            </v-btn>
          </template>
          <span>
            Export Report
          </span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          :loading="loading"
          :total-items="31"
          class="px-1 pb-1"
          item-key="date"
          hide-actions
        >
          <template
            slot="items"
            slot-scope="props"
          >
            <tr @click="props.expanded = !props.expanded">
              <td class="text-xs-right">
                {{ props.item.day }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.total }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.normalNoWd }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.normalWd }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.br }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.totalDealer }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.dealerNoWd }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.dealerWd }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.dealerWawa }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.dealerUnl }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.dealerBr }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.dealerTbg }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.uniqueTotal }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.uniqueNoWd }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.uniqueWd }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.uniqueWawa }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.uniqueUnl }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.uniqueBr }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.uniqueTbg }}
              </td>
            </tr>
          </template>
          <template slot="no-data">
            {{ noDataText }}
          </template>
          <template slot="no-result">
            <v-alert
              :value="true"
              color="error"
              icon="warning"
            >
              {{ noResultText }}
            </v-alert>
          </template>
          <template slot="footer">
            <tr>
              <td class="text-xs-center">
    &nbsp;
              </td>
              <td class="text-xs-center info--text">
                {{ totals.total }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.normalNoWd }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.normalWd }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.br }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.totalDealer }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.dealerNoWd }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.dealerWd }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.dealerWawa }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.dealerUnl }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.dealerBr }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.dealerTbg }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.uniqueTotal }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.uniqueNoWd }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.uniqueWd }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.uniqueWawa }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.uniqueUnl }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.uniqueBr }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.uniqueTbg }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </wide-panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'
import zipcelx from 'zipcelx'

const reportTitle = 'Daily Prepaid Activation'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Management Report', disabled: false, to: '/management/management_kits',
        },
        {
          text: 'Daily Excerpt', disabled: false, to: '/management/daily_view',
        },
        {
          text: 'Daily Prepaid Activation', disabled: true,
        },
      ],
      enableExport: true,
      error: null,
      exportedReport: [],
      exportReportHeader:
          [
            {
              value: 'Day',
              type: 'string',
            },
            {
              value: 'Total Prepaid',
              type: 'string',
            },
            {
              value: 'Normal Prepaid No WD',
              type: 'string',
            },
            {
              value: 'Normal Prepaid + WD',
              type: 'string',
            },
            {
              value: 'BR',
              type: 'string',
            },
            {
              value: 'Total Dealer',
              type: 'string',
            },
            {
              value: 'Dealer No WD',
              type: 'string',
            },
            {
              value: 'Dealer With WD',
              type: 'string',
            },
            {
              value: 'Dealer WAWA',
              type: 'string',
            },
            {
              value: 'Dealer UNL',
              type: 'string',
            },
            {
              value: 'Dealer BR',
              type: 'string',
            },
            {
              value: 'Dealer TBG',
              type: 'string',
            },
            {
              value: 'Total Unique Dealer',
              type: 'string',
            },
            {
              value: 'Unique Dealer No WD',
              type: 'string',
            },
            {
              value: 'Unique Dealer With WD',
              type: 'string',
            },
            {
              value: 'Unique Dealer WAWA',
              type: 'string',
            },
            {
              value: 'Unique Dealer UNL',
              type: 'string',
            },
            {
              value: 'Unique Dealer BR',
              type: 'string',
            },
            {
              value: 'Unique Dealer TBG',
              type: 'string',
            },
            {
              value: 'ONEX',
              type: 'string',
            },
          ],
      expand: true,
      filter: 'All',
      headers: [
        {
          align: 'right',
          sortable: false,
          text: 'Day',
          value: 'day',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Total Prepaid',
          value: 'total',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Normal Prepaid No WD',
          value: 'normalNoWd',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Normal Prepaid + WD',
          value: 'normalWd',
        },
        {
          align: 'center',
          sortable: false,
          text: 'BR',
          value: 'br',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Total Dealer',
          value: 'totalDealer',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Dealer No WD',
          value: 'dealerNoWd',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Dealer With WD',
          value: 'dealerWd',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Dealer WAWA',
          value: 'dealerWawa',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Dealer UNL',
          value: 'dealerUnl',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Dealer BR',
          value: 'dealerBr',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Dealer TBG',
          value: 'dealerTbg',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Total Unique Dealer',
          value: 'uniqueTotal',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Unique Dealer No WD',
          value: 'uniqueNoWd',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Unique Dealer With WD',
          value: 'uniqueWd',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Unique Dealer WAWA',
          value: 'uniqueWawa',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Unique Dealer UNL',
          value: 'uniqueUnl',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Unique Dealer BR',
          value: 'uniqueBr',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Unique Dealer TBG',
          value: 'uniqueTbg',
        },
      ],
      items: [],
      loading: false,
      maxDate: '',
      menu: false,
      month: null,
      monthOf: null,
      noDataText: "There's nothing to display.",
      noResultText: 'No data that fits that criteria.',
      title: reportTitle,
    }
  },
  computed: {
    onexox: function () {
      return this.items.filter(item => item.onex)
    },
    xox: function () {
      return this.items.filter(item => !item.onex)
    },
    all: function () {
      const all = []
      for (let i = 0; i < this.onexox.length; i++) {
        all.push({
          date: this.onexox[i].date,
          total: this.onexox[i].total + (this.xox[i] ? this.xox[i].total : 0),
          normalNoWd: this.onexox[i].normalNoWd + (this.xox[i] ? this.xox[i].normalNoWd : 0),
          normalWd: this.onexox[i].normalWd + (this.xox[i] ? this.xox[i].normalWd : 0),
          br: this.onexox[i].br + (this.xox[i] ? this.xox[i].br : 0),
          totalDealer: this.onexox[i].totalDealer + (this.xox[i] ? this.xox[i].totalDealer : 0),
          dealerNoWd: this.onexox[i].dealerNoWd + (this.xox[i] ? this.xox[i].dealerNoWd : 0),
          dealerWd: this.onexox[i].dealerWd + (this.xox[i] ? this.xox[i].dealerWd : 0),
          dealerWawa: this.onexox[i].dealerWawa + (this.xox[i] ? this.xox[i].dealerWawa : 0),
          dealerUnl: this.onexox[i].dealerUnl + (this.xox[i] ? this.xox[i].dealerUnl : 0),
          dealerBr: this.onexox[i].dealerBr + (this.xox[i] ? this.xox[i].dealerBr : 0),
          dealerTbg: this.onexox[i].dealerTbg + (this.xox[i] ? this.xox[i].dealerTbg : 0),
          uniqueTotal: this.onexox[i].uniqueTotal + (this.xox[i] ? this.xox[i].uniqueTotal : 0),
          uniqueNoWd: this.onexox[i].uniqueNoWd + (this.xox[i] ? this.xox[i].uniqueNoWd : 0),
          uniqueWd: this.onexox[i].uniqueWd + (this.xox[i] ? this.xox[i].uniqueWd : 0),
          uniqueWawa: this.onexox[i].uniqueWawa + (this.xox[i] ? this.xox[i].uniqueWawa : 0),
          uniqueUnl: this.onexox[i].uniqueUnl + (this.xox[i] ? this.xox[i].uniqueUnl : 0),
          uniqueBr: this.onexox[i].uniqueBr + (this.xox[i] ? this.xox[i].uniqueBr : 0),
          uniqueTbg: this.onexox[i].uniqueTbg + (this.xox[i] ? this.xox[i].uniqueTbg : 0),
        })
      }
      return all
    },
    filteredItems: function () {
      let items
      if (this.filter === 'OneXOX') items = this.onexox
      else if (this.filter === 'XOX') items = this.xox
      else items = this.all

      for (const item of items) {
        const date = new Date(DateTime.fromSQL(item.date).toMillis())
        item.day = date.getDate()
      }

      return items
    },
    totals: function () {
      const items = this.filteredItems
      const totals = {
        total: 0,
        normalNoWd: 0,
        normalWd: 0,
        br: 0,
        totalDealer: 0,
        dealerNoWd: 0,
        dealerWd: 0,
        dealerWawa: 0,
        dealerUnl: 0,
        dealerBr: 0,
        dealerTbg: 0,
        uniqueTotal: 0,
        uniqueNoWd: 0,
        uniqueWd: 0,
        uniqueWawa: 0,
        uniqueUnl: 0,
        uniqueBr: 0,
        uniqueTbg: 0,
      }

      for (const item of items) {
        totals.total += item.total
        totals.normalNoWd += item.normalNoWd
        totals.normalWd += item.normalWd
        totals.br += item.br
        totals.totalDealer += item.totalDealer
        totals.dealerNoWd += item.dealerNoWd
        totals.dealerWd += item.dealerWd
        totals.dealerWawa += item.dealerWawa
        totals.dealerUnl += item.dealerUnl
        totals.dealerBr += item.dealerBr
        totals.dealerTbg += item.dealerTbg
        totals.uniqueTotal += item.uniqueTotal
        totals.uniqueNoWd += item.uniqueNoWd
        totals.uniqueWd += item.uniqueWd
        totals.uniqueWawa += item.uniqueWawa
        totals.uniqueUnl += item.uniqueUnl
        totals.uniqueBr += item.uniqueBr
        totals.uniqueTbg += item.uniqueTbg
      }

      return totals
    },
  },
  watch: {
    monthOf: function (val) {
      this.getPrepaidActivations(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
    this.select = 'All'
  },
  methods: {
    allowedMonths: function (month) {
      const ym = month.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2015, 1, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    getPrepaidActivations (month) {
      this.loading = true
      const params = createGetParams({
        month: month,
      })
      this.$rest.get('getPrepaidByDay.php', params)
        .then(function (response) {
          this.items = response.data
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
    exportReport: function () {
      const config = {
        filename: 'Prepaid-Activation' + '-' + this.monthOf,
        sheet: {
          data: [],
        },
      }
      config.sheet.data.push(this.exportReportHeader)
      this.items.forEach(item => {
        const transaction = [
          {
            value: item.date,
            type: 'string',
          },
          {
            value: item.total,
            type: 'string',
          },
          {
            value: item.normalNoWd,
            type: 'string',
          },
          {
            value: item.normalWd,
            type: 'string',
          },
          {
            value: item.br,
            type: 'string',
          },
          {
            value: item.totalDealer,
            type: 'string',
          },
          {
            value: item.dealerNoWd,
            type: 'string',
          },
          {
            value: item.dealerWd,
            type: 'string',
          },
          {
            value: item.dealerWawa,
            type: 'string',
          },
          {
            value: item.dealerUnl,
            type: 'string',
          },
          {
            value: item.dealerBr,
            type: 'string',
          },
          {
            value: item.dealerTbg,
            type: 'string',
          },
          {
            value: item.uniqueTotal,
            type: 'string',
          },
          {
            value: item.uniqueNoWd,
            type: 'string',
          },
          {
            value: item.uniqueWd,
            type: 'string',
          },
          {
            value: item.uniqueWawa,
            type: 'string',
          },
          {
            value: item.uniqueUnl,
            type: 'string',
          },
          {
            value: item.uniqueBr,
            type: 'string',
          },
          {
            value: item.uniqueTbg,
            type: 'string',
          },
          {
            value: item.remark,
            type: 'string',
          },
        ]
        config.sheet.data.push(transaction)
      })
      zipcelx(config)
      this.enableExport = true
    },
  },
}
</script>
